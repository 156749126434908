import './scss/app.scss';

import Vue from 'vue';
import registerVueDirectives from "./directives";
import 'intersection-observer';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate';
import VueOutsideEvents from 'vue-outside-events';
import VueSlideToggle from 'vue-slide-toggle';
import VueObserveVisibility from 'vue-observe-visibility';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

Vue.use(VueAwesomeSwiper);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(VueOutsideEvents);
Vue.use(VueSlideToggle);
Vue.use(VueObserveVisibility);

const files = require.context('./components', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

registerVueDirectives();

import loadScript from './utils/loadScript';

new Vue({
    data() {
        return {
            menuOpened: false,
            searchOpened: false,
            currentPopup: null,
        };
    },
    mounted() {
        loadScript('https://yastatic.net/share2/share.js');
    },
    methods: {
        searchAction(e) {
            if (this.searchOpened) {
                if (this.$refs.headerSearchString.value === '') {
                    e.preventDefault();
                    this.searchOpened = false;
                } else {
                    this.$refs.headerSearch.submit();
                }
            } else {
                e.preventDefault();
                this.searchOpened = true;
            }
        },
        clearSearch() {
            this.searchOpened = false;
            this.$refs.headerSearchString.value = '';
        },
        showPopup(id) {
            this.currentPopup = id;
        },
        visibilityChanged(isVisible, entry) {
            if (isVisible) {
                setTimeout(function () {
                    entry.target.classList.remove('_hidden');
                }, 200);
            } else {
                //entry.target.classList.add('_hidden');
            }
        },
    }
}).$mount('#app');
